import classnames from 'classnames';
import React, { useState } from 'react';
import { Card, Icon } from '@webfx/core-web';
import { Dialog } from '@webfx/web-ui';
import { WistiaPlayer, WistiaProvider } from '@wistia/react-embeds';

import styles from './BannerModal.module.css';

const BannerModal = ({
  show,
  onClose,
  whatThumbnail,
  howThumbnail,
  whatVidLink,
  howVidLink,
  pageName,
}) => {
  const [selectedVideo, setSelectedVideo] = useState(1);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      className={classnames('rounded-2 border border-primary shadow', styles.modalDialog)}
    >
      <Dialog.Panel className={classnames(styles.modalContent, 'p-5')}>
        <div className={styles.header}>
          <div className={styles.closeWrapper}>
            <div className={styles.close} onClick={onClose}>
              <Icon>close</Icon>
            </div>
          </div>
        </div>

        <div className="font-20" data-fx-name="bannerModalTitle">
          How {pageName} Works:
        </div>

        <div className={styles.videoContainer}>
          <div className={styles.thumbnails}>
            <img
              src={whatThumbnail}
              className={classnames(styles.thumbnail, {
                [styles.unselected]: selectedVideo !== 1,
              })}
              onClick={() => setSelectedVideo(1)}
              data-fx-name="whatVideoThumbnail"
            />
            <img
              src={howThumbnail}
              className={classnames(styles.thumbnail, {
                [styles.unselected]: selectedVideo !== 2,
              })}
              onClick={() => setSelectedVideo(2)}
              data-fx-name="howVideoThumbnail"
            />
          </div>

          <WistiaProvider>
            {selectedVideo === 1 ? (
              <Card className={classnames(styles.card, 'p-0')}>
                <div className={styles.playerWrapper}>
                  <WistiaPlayer hashedId={whatVidLink} seo={true} videoFoam={true}></WistiaPlayer>
                </div>
              </Card>
            ) : (
              <Card className={classnames(styles.card, 'p-0')}>
                <div className={styles.playerWrapper}>
                  <WistiaPlayer hashedId={howVidLink} seo={true} videoFoam={true}></WistiaPlayer>
                </div>
              </Card>
            )}
          </WistiaProvider>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default BannerModal;
