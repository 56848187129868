import React, { Suspense } from 'react';
import classnames from 'classnames';

import { Snackbar, Typography, Svg, Link, IconLabel } from '@webfx/core-web';
import { useDocumentTitle, usePageView } from '@webfx/web-hooks';

import MarketingCloud from '../../assets/marketingcloudfx.svg';
import WebFX from '../../assets/webfx.svg';

import { SignInForm } from '../../components/SignInFormV2/SignInForm.jsx';

const SignInAnimation = React.lazy(() =>
  import('../../components/SignInAnimation/SignInAnimation.jsx')
);

import styles from './SignIn.module.css';

export function SignIn() {
  useDocumentTitle('Sign In | RevenueCloudFX');
  usePageView();

  return (
    <div className={classnames(styles.page)}>
      <div className={classnames(styles.form)}>
        <Snackbar className="snackbar bg-danger" hideCloseButton icon="warning" variant="error" />
        <div
          className={classnames([
            'd-flex flex-wrap align-items-center justify-content-between p-3 bold font-13 mb-4',
          ])}
        >
          <Link
            plain
            external={false}
            to="https://www.webfx.com/"
            className={classnames(styles.topLink, 'mx-auto mb-2')}
          >
            <IconLabel icon="keyboard_arrow_left" label="Return to WebFX.com" />
          </Link>
          <Link
            plain
            external={false}
            to="https://www.webfx.com/Free-Quote.php"
            className={classnames([
              'border border-primary rounded mx-auto mb-2 pl-4 pr-4 pt-2 pb-2 ',
              styles.topLink,
            ])}
          >
            <IconLabel
              icon="business_center"
              label="Get a Strategy & Proposal"
              labelClassName={classnames(styles.topLink)}
            />
          </Link>
        </div>
        <Snackbar className="snackbar" hideCloseButton icon="warning" variant="error" />
        <div className={styles.verticalCenter}>
          <Svg
            className="mx-auto"
            src={MarketingCloud}
            style={{ width: 165, height: 21, marginBottom: '38px' }}
          />
          <SignInForm />
        </div>
        <div
          className={`${styles.poweredByWrapper}`}
          style={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }}
        >
          <div className="d-flex justify-content-center mb-1">
            <Typography className="d-flex align-items-center font-11 m-0" component="p" variant="p">
              Powered by
            </Typography>
            <div className="d-flex align-items-center">
              <a href="https://www.webfx.com/" className="ml-2">
                <Svg src={WebFX} style={{ width: 53, lineHeight: 0 }} />
              </a>
            </div>
          </div>
          <div className="font-11 pt-1 text-gray-500">
            Having trouble? Use the{' '}
            <Link className="text-gray-700" to="/signin-v1">
              legacy login
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.animation}>
        <Suspense fallback={<div />}>
          <SignInAnimation />
        </Suspense>
      </div>
    </div>
  );
}
