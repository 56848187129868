const educationLevels = [
  {
    label: 'No College Degree',
    value: 'no college degree',
  },
  {
    label: "Associate's Degree",
    value: 'associates',
  },
  {
    label: "Bachelor's Degree",
    value: 'bachelors',
  },
  {
    label: "Master's Degree",
    value: 'masters',
  },
  {
    label: 'Doctoral Degree',
    value: 'doctorates',
  },
];

module.exports = educationLevels;
