import { api, Icon } from '@webfx/core-web';
import { useActiveSite, useAuth, useFlags, useApp, useCan, usePermissions } from '@webfx/web-hooks';
import useIsOutboundAndHomeServices from '@webfx/web-hooks/src/useIsOutboundAndHomeServices';

import NewStrategyIcon from '@webfx/playbook-web/src/ui/components/NewStrategyIcon';

/**
 * Returns the list of apps the user has access to.
 * @returns {Array}
 */
export default function useApps() {
  const apps = [];
  const [activeApp] = useApp();
  const activeSite = useActiveSite({ $join: ['encryption', 'parent'] });

  const isOutboundAndHomeServices = useIsOutboundAndHomeServices(activeSite?.companyId);

  const canReadActiveSite = useCan('read', 'sites', { siteId: activeSite.siteId });
  const { user, isAdmin } = useAuth();
  const permissions = usePermissions();

  const siteId = activeSite.siteId && canReadActiveSite ? `/${activeSite.siteId}` : '';
  const rules = permissions.rulesFor('read', 'project');

  const flags = useFlags();
  const userApps = api.useQuery(
    ['users', user?.userId, { $select: ['userId'], $join: ['products'] }],
    {
      enabled: !!user?.userId,
    }
  );

  const projectIds = isAdmin
    ? []
    : rules.reduce((ids, rule) => {
        if (!rule?.conditions?.projectId) {
          return ids;
        } // not an id rule

        return ids.concat(rule.conditions.projectId.$in ?? [rule?.conditions?.projectId]);
      }, []);

  (userApps.data?.products ?? [])
    .filter((p) => !p.parentId || p.productId === 'analytics') // Subproducts should not be included in the list of apps (Analytics is an exception because we want it to show in the top navbar).
    .forEach((product) => {
      // this validation handles inverted (disabled) rules.
      if (permissions.cannot('read', product.productId)) {
        return;
      }

      // TeamAI limited to flag usage to start
      if (product.productId === 'teamai' && !flags.teamai) {
        return;
      }

      let to = (product.matchPath ?? '')
        .replace(/\(.*revenuecloudfx.*\)/, 'revenuecloudfx')
        .replace(
          ':siteId?',
          // this valdiates the current active site has permissions to the
          // specific productId
          // replaces with dashboard if it does not
          (activeSite.siteId && (product.siteIds ?? []).length === 0) ||
            (product.siteIds ?? []).includes(activeSite.siteId)
            ? activeSite.siteId
            : ''
        );

      if (product.productId === 'analytics') {
        if (!flags.analyticsFxRevamp || !flags.analytics) {
          return;
        } else {
          to = `/revenuecloudfx${siteId}/analytics/reports/category/monthly-reports`;
        }
      }

      if (product.productId === 'twfx') {
        to =
          isAdmin || permissions.can('readMultipleProjects') || !projectIds.length
            ? '/dashboard'
            : `/projects/${projectIds[0]}/overview`;
      }

      // the MCFX matchpath is different from the base path we want to "redirect to"
      if (product.productId === 'mcfx') {
        to = `/revenuecloudfx${siteId}/dashboard`;
      }

      // encrytped sites must hide nutshell
      if (
        product.productId === 'nutshell' &&
        activeSite.siteId &&
        (activeSite.encryption || activeSite.parent)
      ) {
        return;
      }

      if (product.productId === 'playbook' && isOutboundAndHomeServices) {
        return;
      }

      apps.push({
        text: product.name,
        to: to.replace('//', '/'), // hacky way to easily ensure the replacement of siteId in the matchpath didn't create // slashes
        isActive: activeApp === product.productId,
        extraIcon: getProductExtraIcon(product, isAdmin),
      });
    });

  if (!flags.analyticsFxRevamp) {
    apps.push({
      text: 'CompetitorSpyFX',
      to: `/competitorspy${siteId}`,
      isActive: activeApp === 'competitorspy',
    });
  }

  return apps;
}

const getProductExtraIcon = (product, isAdmin) => {
  if (!isAdmin && product.productId === 'playbook') {
    return <NewStrategyIcon iconSize={32} className="mt-n1 ml-1" onMenu={true} />;
  }

  if (product.productId === 'teamai') {
    return <Icon className="ml-1 text-red font-14">lock</Icon>;
  }

  return null;
};
