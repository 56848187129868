import classnames from 'classnames';
import { useFlags } from '@webfx/web-hooks';
import { Icon } from '@webfx/core-web';
import { Dropdown as HeadlessDropdown } from '@webfx/web-ui';

import { version } from '../../../../../../../../package.json';

// Need this at parent level due to necessary interactions with logo hover
import styles from '../Header.module.css';

const getCurrentEnv = () => {
  const envMappings = [
    { substring: 'localhost', env: 'Local' },
    { substring: 'staging.app.dev', env: 'Staging' },
    { substring: 'app.dev', env: 'Dev' },
    { substring: 'app.master', env: 'Master' },
    { substring: 'app.next', env: 'Next' },
    { substring: 'app.webfx', env: 'Prod' },
  ];
  return (
    envMappings.find((mapping) => window.location.host.includes(mapping.substring))?.env || null
  );
};

const envDataMappings = {
  // Green colors represent a creative space for development, "like a garden where code grows" - ChatGPT
  Local: { host: 'localhost', bgColor: 'green-03', bgHoverColor: 'green-04', color: 'green-04' },
  Dev: { host: null, bgColor: 'green-05', bgHoverColor: 'green-04', color: 'green-05' },
  // Safe purple color for peace of mind with testing and verification
  Staging: {
    host: 'staging.app.dev.webfx.com',
    bgColor: 'purple-03',
    bgHoverColor: 'purple-04',
    color: 'purple-04',
  },
  // Attention-grabbing orange color due to using production database
  Master: {
    host: 'app.master.webfx.com',
    bgColor: 'orange-04',
    bgHoverColor: 'orange-05',
    color: 'orange-05',
  },
  // Warning red color due to using production database; also signifies excitement for new features and updates
  Next: { host: 'app.next.webfx.com', bgColor: 'red', bgHoverColor: 'red-05', color: 'red-05' },
  // No color mods for production
  Prod: { host: 'app.webfx.com', bgColor: null, color: null },
};

const getCurrentEnvData = () => {
  const env = getCurrentEnv();
  return envDataMappings[env] ?? {};
};

const getLink = (value) => {
  const newHostname = envDataMappings[value]?.host;
  if (newHostname) {
    const newUrl = new URL(window.location.href);
    newUrl.hostname = newHostname;
    if (value === 'Local') {
      newUrl.protocol = 'http';
      newUrl.port = '4000';
    } else {
      newUrl.protocol = 'https';
      newUrl.port = '';
    }
    return newUrl.toString();
  }
  return null;
};

const EnvSwitcher = () => {
  const currentEnv = getCurrentEnv();
  const currentEnvData = getCurrentEnvData();
  const flags = useFlags();

  if (!flags.envSwitcher) {
    return null;
  }

  return (
    <HeadlessDropdown
      options={Object.keys(envDataMappings)
        .filter((env) => env !== 'Dev')
        .map((key) => {
          const url = getLink(key);
          return {
            value: key,
            label: (
              <div className="d-flex align-items-center w-100 gap-2 position-relative">
                <a
                  href={url}
                  className={classnames(
                    styles.envSwitcherLink,
                    'd-flex flex-grow-1 align-items-center gap-2 text-gray-700'
                  )}
                >
                  <Icon
                    className={`${
                      envDataMappings[key]?.bgColor
                        ? `text-${envDataMappings[key]?.bgColor}`
                        : 'text-blue-400'
                    }`}
                  >
                    circle
                  </Icon>
                  {key}
                </a>
                <a
                  href={url}
                  className={classnames(
                    styles.envSwitcherLinkExternal,
                    'd-flex align-items-center position-absolute p-1 rounded text-gray-550 text-hover-gray-700 bg-hover-white'
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon className="font-16">open_in_new</Icon>
                </a>
              </div>
            ),
          };
        })}
      classNames={{
        dropdownContainer: classnames(styles.envSwitcherContainer, {
          [styles.hideByDefault]: currentEnv === 'Prod',
        }),
        dropdownButton: classnames(
          styles.envSwitcher,
          currentEnvData.color ? `text-${currentEnvData.color}` : null,
          'font-10 bg-white border-0 rounded-pill shadow-sm'
        ),
        listBoxOptions: 'p-0',
        listBoxOption: 'p-0',
      }}
      buttonText={['Prod', 'Next'].includes(currentEnv) ? `${currentEnv} (${version})` : currentEnv}
      selected={currentEnv}
    />
  );
};

export { EnvSwitcher, getCurrentEnvData };
