import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import classnames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, FormikInput, Icon, Typography, Svg, Spinner } from '@webfx/core-web';
import { useParamFilters } from '@webfx/web-hooks';
import { useLogin } from '../../hooks/useLogin';
import IconCheckInbox from '../../assets/icon-check-inbox.svg';
import { GoogleSignInButton } from '../GoogleSignInButton';
import { Office365SignInButton } from '../Office365SignInButton';

import styles from './SignInForm.module.css';

const RESEND_EMAIL_INTERVAL = 120;
/**
 *
 */
export function SignInForm() {
  const login = useLogin({
    onSuccess: () => {
      setShowVerificationScreen(true);
      setCountdown(RESEND_EMAIL_INTERVAL);
    },
  });
  const { filters, setFiltersTo } = useParamFilters({});
  const { token } = filters;

  useEffect(() => {
    if (token) {
      setFiltersTo({});
      login.handleTokenLogin(token);
    }
  }, [token]);

  const [showVerificationScreen, setShowVerificationScreen] = useState(false);
  const [countdown, setCountdown] = useState(RESEND_EMAIL_INTERVAL);
  const [showError, setShowError] = useState(true);

  if (login.error && !showError) {
    setShowError(true);
  }

  const handleErrorClose = () => {
    setShowError(false);
    login.setError(null);
  };

  useEffect(() => {
    let timer;
    if (showVerificationScreen && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showVerificationScreen, countdown]);

  return (
    <div className={classnames(['container', styles.container])}>
      <Typography
        component="h1"
        variant="jumboTitle1"
        className={classnames(['text-center', styles.title])}
      >
        Your Competitive Advantage through Data Intelligence
      </Typography>

      {!showVerificationScreen ? (
        <div className={styles.formWrapper}>
          <Formik {...login.form}>
            {() => (
              <Form>
                <div data-fx-name="loginError">
                  <SignInError message={login.error} show={showError} onClose={handleErrorClose} />
                </div>
                <Typography component="h6" variant="h6" className={styles.inputLabel}>
                  Sign in with email
                </Typography>
                <FormikInput
                  data-fx-name="email"
                  placeholder="Your email address"
                  className={'mb-2'}
                  style={{ fontSize: '13px', fontWeight: 400, height: 34 }}
                  name="email"
                  type="email"
                  errorVisible={false}
                  icon="email"
                  iconOutined
                  iconPosition="right"
                  tabIndex="1"
                />
                <div className={styles.btnWrap} data-fx-name="submit">
                  <Button
                    data-fx-name="submit"
                    className={classnames(styles.submitButton, 'btn-primary-vivid', {
                      [styles.disabled]: login.disabled || login.form.isSubmitting,
                    })}
                    type="submit"
                    text={login.isLoading ? <Spinner /> : 'Continue with Email ->'}
                    fullWidth
                    isLoading={login.disabled}
                    disabled={login.disabled || login.form.isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <div className={styles.separatorWrapper}>
            <hr />
            <Typography className={styles.separatorText} uppercase bold component="p" variant="p">
              Or
            </Typography>
            <hr />
          </div>

          <div className={`${styles.signWithWrapper} d-flex flex-column justify-content-center`}>
            <ErrorBoundary FallbackComponent={() => null} className="pb-5">
              <GoogleSignInButton />
            </ErrorBoundary>
            <Office365SignInButton
              clearErrors={() => login.setError(null)}
              isLoading={login.disabled}
            />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <Svg src={IconCheckInbox} style={{ width: 96 }} className="mx-auto my-3" />
          <div className="font-weight-bold mb-2">Please check your inbox!</div>
          <div className="mb-5">
            Please check your inbox. We&apos;ve emailed you a link and clicking it will
            automatically sign you in. The link expires in 30 minutes.
          </div>
          <hr />
          <div className={classnames([styles.fontWeightLightBold, 'mb-2'])}>
            Did not receive the email?
          </div>
          {countdown > 0 ? (
            <div className="mb-5 text-muted">Resend in {countdown} secs...</div>
          ) : (
            <div className="mb-5">
              <button
                type="button"
                onClick={async () => {
                  await login.resendEmail();
                }}
                className="text-primary btn btn-link p-0 border-0"
                disabled={login.isLoading}
              >
                {login.isLoading ? <Spinner /> : 'Resend email'}
              </button>
            </div>
          )}
          <Button
            className="mt-5 py-2 text-dark"
            variant="outline-secondary"
            text="<- Back to SSO Sign In"
            fullWidth
            onClick={() => setShowVerificationScreen(false)}
          />
        </div>
      )}
    </div>
  );
}

function SignInError({ message, show, onClose }) {
  if (!message) {
    return null;
  }

  return show ? (
    <div className={`${styles.alert} shadow`}>
      {message}
      <button type="button" onClick={onClose}>
        <Icon className="ml-2 text-white font-16">close</Icon>
      </button>
    </div>
  ) : null;
}
