import React from 'react';
import { Icon, Image, Link, Svg } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';
import { DateTime } from 'luxon';

import AdvertisingAILogo from '../../../../../assets/images/advertising-ai-logo.svg';

const EnabledIntegration = ({ siteId, type, integration }) => {
  const { url } = useRouter();
  const { platform, lastSync, logo } = integration;

  const lastSyncDate =
    lastSync === 'real-time' ? 'in real time' : DateTime.fromISO(lastSync).toFormat('LLL dd, yyyy');

  return (
    <div className="font-14 text-gray-550 pr-4">
      {type === 'crm' ? (
        <>
          <div className="font-weight-normal">Integrated with:</div>
          {logo ? <Image src={logo} alt={platform} height="25" /> : 'Custom CRM'}
        </>
      ) : null}
      {type === 'ads' ? (
        <>
          <Svg
            src={AdvertisingAILogo}
            className="text-right"
            svgStyle={{ height: 20, width: 'auto' }}
          />
        </>
      ) : null}
      <div className="d-flex align-items-center justify-content-end flex-column flex-lg-row pt-1">
        <div className="d-flex align-items-center">
          <Icon className="font-16 user-select-none">autorenew</Icon>
          <span className="pl-1 text-nowrap">{lastSyncDate}</span>
        </div>
        <Link
          to={url({
            route:
              type === 'crm' ? 'rcfxIntegrationsDataWarehouse' : 'rcfxIntegrationsConversionFeeds',
            siteId,
          })}
          className="pl-1 curosor-pointer text-blue-vivid-300 user-select-none font-12 text-nowrap"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

export default EnabledIntegration;
