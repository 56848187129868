import React from 'react';
import { Svg } from '@webfx/core-web';
import classnames from 'classnames';
import DocIcon from '../../../assets/images/document.svg';
import HelpVideoIcon from '../../../assets/images/help-video.svg';

import styles from './HelpCenterWidget.module.css';

const HelpArticle = ({ article, useVideoIcon, condensed = true }) => {
  const BASE_URL = `${window.location.origin}/help-center/`;
  const truncateDescription = (text) => {
    return text.slice(0, 60) + '[...]';
  };

  const Icon = useVideoIcon ? HelpVideoIcon : DocIcon;
  const href = article.helpCenterUrl || `${BASE_URL}search?slug=${article.slug}`
  const shortVersion = truncateDescription(article.short_version);

  if (condensed) {
    return <CondensedHelpArticle title={article.title} href={href} icon={Icon} />;
  }

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={classnames('row', styles.article)}>
        <div className="col-1">
          <Svg src={Icon} className={styles.docIcon} />
        </div>
        <div className="col">
          <div className={classnames(styles.articleTitle)}>{article.title}</div>
          <div className={classnames('mt-2', styles.articleDesc)}>{shortVersion}</div>
        </div>
      </div>
    </a>
  );
};

function CondensedHelpArticle({ icon, href, title }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={classnames('d-flex align-items-center', styles.article)}>
        <Svg src={icon} style={{ width: '18px', flexShrink: 0 }} />
        <div className={classnames('font-14 ml-2 semibold', styles.articleTitle)}>{title}</div>
      </div>
    </a>
  );
}

export default HelpArticle;
