/**
 * Copied from @webfx-rd/cloud-utils
 * Converts a touchpoint status from mongo -> spanner impressions.
 * As of 2024-09-19, the source of truth for this logic can be found
 * [here](https://docs.google.com/spreadsheets/d/11BxNIKg78_F91WgV5qK8lcITsCK4mZzeHaIBScjuxA4/edit?usp=sharing).
 * @param {string} status Lead or call touchpoint status from mongo.
 * - In the `lead` collection, this is the `status` field.
 * - In the `call` collection, this is the `touchpointStatus` field.
 * @returns The spanner `impressions.status` equivalent.
 */
module.exports = function convertMongoTouchpointStatus(status) {
  if (typeof status !== 'string') {
    return null;
  }
  switch (status.toLowerCase()) {
    // This first section contains all the one-to-one mappings
    case 'disqualified':
    case 'spam':
    case 'subscription':
      return status.toLowerCase();
    // Below this line are the many-to-one mappings
    case 'mql':
    case 'prospect':
    case 'new lead':
    case 'new_lead':
      return 'new_lead';
    case 'lost':
    case 'closed lost':
      return 'closed_lost';
    case 'sale':
    case 'closed won':
      return 'closed_won';
    case 'upsell/reorder request':
    case 'upsell request':
    case 'upsell':
      return 'upsell';
    case 'lead follow-up':
    case 'followup':
      return 'followup';
    case 'support / question':
    case 'support/question':
    case 'support':
      return 'support';
    case 'downgrade / cancellation request':
    case 'downgrade/cancelation request':
    case 'downgrade':
      return 'downgrade';
    case 'active':
    case 'inactive':
    case 'unknown':
    case 'uncategorized':
      return 'uncategorized';
    default:
      return null;
  }
};
