import React from 'react';
import { Icon, Svg } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';
import { ProductLogo } from '@webfx/web-ui';
import useLatestIntegrations from '@webfx/common-web/src/hooks/useLatestIntegrations';

import EnabledIntegration from './Components/EnabledIntegration';
import DisabledIntegration from './Components/DisabledIntegration';

import AdvertisingAILogo from '../../../../assets/images/advertising-ai-logo.svg';

import styles from './LatestIntegrations.module.css';

const DisabledCRMIntegration = ({ siteId }) => (
  <DisabledIntegration
    siteId={siteId}
    logo={
      <>
        <div className="font-weight-bold font-14 text-nowrap">No CRM Integrations</div>
        <Icon className="font-16 text-orange-03 pl-1">cancel</Icon>
      </>
    }
    popoverLogo={<ProductLogo product="IntegrationsFX" style={{ height: '26px' }} />}
    route="mcfxIntegrationsCRMpipelines"
    text="Connect RevenueCloudFX and your CRM to see top revenue-driving channels, calculate marketing return, and improve campaign performance by also passing CRM data to ad platforms."
  />
);

const DisabledAdsIntegration = ({ siteId }) => (
  <DisabledIntegration
    siteId={siteId}
    logo={<Svg src={AdvertisingAILogo} className={styles.logo} />}
    popoverLogo={<Svg src={AdvertisingAILogo} className={styles.logo} />}
    route="rcfxIntegrationsConversionFeeds"
    text="Send your customers from RevenueCloudFX to Advertising Campaigns to target the right people for enhanced ROI & accelerated revenue"
  />
);

const Integration = ({ siteId, type, integration }) =>
  integration?.lastSync ? (
    <EnabledIntegration siteId={siteId} type={type} integration={integration} />
  ) : type === 'crm' ? (
    <DisabledCRMIntegration siteId={siteId} />
  ) : (
    <DisabledAdsIntegration siteId={siteId} />
  );

const LatestIntegrations = () => {
  const { siteId } = useActiveSite();
  const { data: integrations, isLoading } = useLatestIntegrations(siteId);

  const crmIntegration = integrations?.crm;
  const adsIntegration = integrations?.ads;

  if (isLoading) {
    return null;
  }

  return (
    <div className={`d-flex align-items-end pr-4 my-auto ${styles.slideDown}`}>
      <Integration siteId={siteId} type="crm" integration={crmIntegration} />
      <Integration siteId={siteId} type="ads" integration={adsIntegration} />
    </div>
  );
};

export default LatestIntegrations;
