import React from 'react';
import { Nav } from 'react-bootstrap';
import classnames from 'classnames';
import { ContentContainer, Spinner, Icon, Paper, ErrorPage } from '@webfx/core-web';
import {
  useAuth,
  useFlags,
  useDocumentTitle,
  useNutshellAuth,
  useNotifications,
} from '@webfx/web-hooks';
import { Switch, Route, NavLink } from 'react-router-dom';
import { useRouter } from '@webfx/web-hooks';
import { NutshellEmbed } from '@webfx/web-ui-connected';
import Profile from './Profile';
import Tokens from './Tokens';

import styles from './MyInfo.module.css';

const EmailNotifications = React.lazy(() => import('./EmailNotifications'));

/**
 *
 */
const MyInfo = () => {
  const { user = {}, isAdmin } = useAuth();
  const flags = useFlags();
  const canViewNotifications = flags.iamEmailpreferences;
  const { route } = useRouter();
  useDocumentTitle('My Info | RevenueCloudFX');

  return (
    <ContentContainer>
      <Paper>
        <div className="d-flex">
          <aside className={classnames(['col-3', styles.sidebar])}>
            <Nav variant="pills" className="flex-column">
              <NavItem to={route('myInfo')} label="Profile" icon="person" />
              <NavItem to={route('myInfoApiKeys')} label="API Keys" icon="vpn_key" />
              {isAdmin ? (
                <NavItem to={route('myInfoScheduler')} label="SchedulerFX" icon="mood" />
              ) : null}
              {canViewNotifications ? (
                <NavItem
                  to={route('myInfoEmailNotifications')}
                  label="Email Notifications"
                  icon="email"
                />
              ) : null}
            </Nav>
          </aside>
          <div className="flex-fill overflow-auto pl-3">
            <Switch>
              <Route exact path={route('myInfo')} component={Profile} />
              <Route exact path={route('myInfoApiKeys')} component={Tokens} />
              {isAdmin ? (
                <Route exact path={route('myInfoScheduler')} component={FXerNutshellEmbedframe} />
              ) : null}
              {canViewNotifications ? (
                <Route exact path={route('myInfoEmailNotifications')}>
                  <EmailNotifications viewingUser={user} />
                </Route>
              ) : null}
            </Switch>
          </div>
        </div>
      </Paper>
    </ContentContainer>
  );
};

const FXerNutshellEmbedframe = () => {
  const embedUrl = 'https://nutshell.webfx.com/embed/scheduler';
  const auth = useNutshellAuth({ authFor: 'embed', instanceId: '365724' });
  const { token } = auth?.data || {};
  const { toast } = useNotifications();
  const isErrorNotificationShown = React.useRef(false);
  if (auth.isFetched && !token) {
    if (!isErrorNotificationShown.current) {
      toast.error(
        'Your Nutshell account is not available. Please contact your account manager for assistance.'
      );
      isErrorNotificationShown.current = true;
    }

    return (
      <ErrorPage title={'404'} subtitle={'Your Nutshell account is not available'}>
        {<p>Please contact your account manager for assistance.</p>}
      </ErrorPage>
    );
  }

  if (!token) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }
  return <NutshellEmbed token={token} embedURL={embedUrl} />;
};

const NavItem = ({ to, label, icon }) => {
  const [iconType] = icon.split('_outlined');
  const outlined = icon.endsWith('_outlined');

  return (
    <Nav.Item>
      <NavLink
        to={to}
        className={classnames('nav-link', styles.navLink)}
        activeClassName={classnames('active', styles.active)}
        exact
      >
        {iconType ? (
          <Icon type={iconType} outlined={!!outlined} className={styles.navIcon} />
        ) : null}
        <span>{label}</span>
      </NavLink>
    </Nav.Item>
  );
};

export default MyInfo;
