import React from 'react';
import { Badge, Button, Link, Popover } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

const DisabledIntegration = ({ logo, popoverLogo, text, siteId, route }) => {
  const { url } = useRouter();

  return (
    <div className="font-14 pr-4">
      <div className="d-flex align-items-center">{logo}</div>
      <div className="d-flex align-items-center justify-content-end flex-column flex-lg-row pt-2">
        <Badge className="bg-blue-vivid-100 text-blue-500 font-12 py-1 cursor-pointer">
          <Link to={url({ route, siteId })}>Request Set Up</Link>
        </Badge>
        <div className="pl-1 curosor-pointer text-blue-vivid-300">
          <Popover
            triggerText={
              <div className="user-select-none font-12 text-nowrap">What&apos;s this?</div>
            }
            trigger={'click'}
            placement={'bottom'}
            triggerClassName="d-flex align-items-center pr-1"
            content={
              <>
                {popoverLogo}
                <div className="font-weight-normal pt-1">{text}</div>
                <div>
                  <Button
                    to={url({ route, siteId })}
                    variant="primary"
                    text="Request Set Up"
                    className="bg-blue-vivid-300 border-blue-vivid-300 px-3 mt-2 mb-1 font-weight-bold"
                  />
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DisabledIntegration;
