// a list of email-marketing-fx addon item-codes
const emailMarketingAddons = [
  'ITEM-161',
  'ITEM-162',
  'ITEM-163',
  'ITEM-164',
  'ITEM-165',
  'ITEM-167',
  'ITEM-168',
  'ITEM-169',
  'ITEM-754',
  'ITEM-755',
  'ITEM-756',
  'ITEM-757',
  'ITEM-758',
  'ITEM-759',
  'ITEM-760',
  'ITEM-761',
  'ITEM-762',
];

const emfxOveragesAddons = [
  'ITEM-1039',
  'ITEM-1040',
  'ITEM-1041',
  'ITEM-1042',
  'ITEM-1043',
  'ITEM-1044',
];

// a list of adtechfx addon item-codes
const adtechfxAddons = {
  enterprise: [
    'ITEM-942',
    'ITEM-935',
    'ITEM-936',
    'ITEM-938',
    'ITEM-939',
    'ITEM-5013',
    'ITEM-5015',
  ],
  lite: [
    'ITEM-940',
    'ITEM-98',
    'ITEM-600',
    'ITEM-605',
    'ITEM-118',
    'ITEM-607',
    'ITEM-134',
    'ITEM-112',
    'ITEM-680',
    'ITEM-992',
    'ITEM-988',
    'ITEM-989',
    'ITEM-990',
    'ITEM-991',
  ],
};

const outdatedPlans = {
  litePPC: ['ITEM-940'],
  outdatedPPC: ['ITEM-98', 'ITEM-112', 'ITEM-600', 'ITEM-605', 'ITEM-680'],
  outdatedProgram: ['ITEM-614', 'ITEM-616', 'ITEM-621', 'ITEM-622', 'ITEM-623'],
  outdatedLSA: ['ITEM-125', 'ITEM-126'],
};

module.exports = { emailMarketingAddons, adtechfxAddons, outdatedPlans, emfxOveragesAddons };
