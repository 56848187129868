import numbro from 'numbro';
import { DateTime } from 'luxon';

/**
 * Gets the asset status to display.
 *
 * @param params
 * @param params.copyPage
 * @param params.status
 * @param params.link
 * @param params.contentType
 * @param params.contentOutline
 */
const getAssetStatusForUI = ({ copyPage, status, link, contentType, contentOutline }) => {
  if (link?.completedDate && contentType?.name === 'Authority Link') {
    return 'Live and Driving Traffic';
  }
  let uIStatus;

  if (!copyPage || contentOutline) {
    return status;
  }

  switch (copyPage?.status.toLowerCase()) {
    case 'approved':
      uIStatus = 'Publishing Underway';
      break;
    case 'in revision':
      uIStatus = 'In Revision';
      break;
    case 'launched':
      uIStatus = 'Live and Driving Traffic';
      break;
    case 'pending approval':
    case 'pending edits':
      uIStatus = 'Approve to Drive Results';
      break;
    case 'requested':
      uIStatus = 'Research Underway';
      break;
    default:
      uIStatus = status;
  }
  return uIStatus;
};

/**
 *
 * @param assetStatus
 */
const canViewOnContentGenius = (assetStatus = '') => {
  const canViewStatuses = [
    'requested',
    'pending approval',
    'pending implementation',
    'implemented',
  ];

  let canView = false;

  canViewStatuses.forEach((status) => {
    if (assetStatus?.toLowerCase() === status?.toLowerCase()) {
      canView = true;
    }
  });

  return canView;
};

/**
 * Formats the points to display.
 *
 * @param cost
 * @param points
 */
const formatPoints = (points) => {
  if (!points) {
    return '0';
  }
  return numbro(points).format({
    trimMantissa: true,
    mantissa: 3,
  });
};

/**
 *
 * @param asset
 * @param timezone
 * @param status
 */
const getLastStatusUpdate = (asset, timezone, status) => {
  if (!asset.statusHistory) {
    return null;
  }

  let matchingStatus;
  for (let i = 0; i < asset.statusHistory.length; i++) {
    if (
      asset.statusHistory[i].status === status &&
      (status !== 'Topic Approved' ||
        !['Content Brief Needs Approval', 'Content Brief Approved'].includes(
          asset.statusHistory[i + 1].status
        ))
    ) {
      // If the statuses match and the Topic Approved state was reached by hitting the approve button,
      // not moving backwards from Content Brief Needs Approval or Content Brief Approved via removing the outline
      matchingStatus = asset.statusHistory[i];
      break;
    } else if (asset.statusHistory[i].status === 'Planned' && status === 'Topic Approved') {
      // If a topic is approved and an outline is already present, the status will move directly from Planned to Content Brief Needs Approval
      // Therefore, we need to grab the timestamp from the Content Brief Needs Approval status following the last Planned status for the topic approval timestamp
      matchingStatus = asset.statusHistory[i - 1];
      break;
    }
  }

  if (matchingStatus) {
    return timezone
      ? DateTime.fromISO(matchingStatus.createdAt, { zone: 'Etc/GMT+0' }).setZone(timezone)
      : DateTime.fromISO(matchingStatus.createdAt);
  }
};

/**
 *
 * @param key
 * @param state
 * @param onChange
 */
const handleAddToList = (key, state, onChange) => {
  const collections = {
    keyword: {
      list: 'keywords',
    },
    link: {
      list: 'links',
    },
  };

  if (state?.[key] === '' || state?.[key] === null) {
    return;
  }
  const splitted = state?.[key]?.split(',');
  const list = (state?.[collections[key].list] ?? []).concat(splitted);

  // Add keyword to list
  onChange(collections[key].list, list);
  //Clear the keyword text field
  onChange(key, '');
};

/**
 *
 * @param key
 * @param index
 * @param state
 * @param onChange
 */
const handleRemoveFromList = (key, index, state, onChange) => {
  const list = state?.[key];
  const remaining = [...list];

  remaining.splice(index, 1);

  onChange(key, remaining);
};

/**
 *
 * @param root0
 * @param root0.key
 * @param root0.index
 * @param root0.state
 * @param root0.onChange
 * @param root0.updatedValue
 */
const handleUpdateListEntry = ({ key, index, state, onChange, updatedValue = '' }) => {
  const list = state?.[key];
  const remaining = [...list];

  remaining.splice(index, 1, updatedValue);

  onChange(key, remaining);

  //Reset the entry value to ensure that only the modified values are applied.
  onChange('entry', null);
};

/**
 *
 * @param data
 */
const computeFuelFxVisitorCount = (data) => {
  const totalVisitor = data?.reduce(
    (aggregate, asset) => aggregate + (asset?.copyPage?.visitorCount ?? 0),
    0
  );
  return totalVisitor !== 0 ? totalVisitor : null;
};

/**
 * If the asset is linked to multiple copy pages, the cost will be the asset type cost.
 * If the client has enterprise pricing, the cost will be multiplied by the service's enterprise pricing multiplier.
 * Otherwise, the cost will be the total cost of the link request.
 * @param {object} asset - The Planner Asset Objet
 * @param {boolean} isEnterprisePricing - If the client has enterprise pricing applied to them.
 * @returns {string} The formatted cost of the asset
 */
const getAssetCost = (asset, isEnterprisePricing = false) => {
  const requestCost =
    !asset?.copyPageCount || asset?.copyPageCount > 1 ? null : asset?.link?.pointCost;

  const assetTypeCost = asset.contentType?.externalService?.cost ?? asset?.contentType?.cost;
  const enterprisePricingMultiplier =
    asset?.contentType?.externalService?.enterprisePricingMultiplier ??
    asset?.contentType?.enterprisePricingMultiplier ??
    1;

  const totalAssetCost = isEnterprisePricing
    ? assetTypeCost * enterprisePricingMultiplier
    : assetTypeCost;

  const points = requestCost ?? totalAssetCost;

  return points
    ? numbro(points).format({
        trimMantissa: true,
        mantissa: 3,
      })
    : 0;
};

export {
  getAssetStatusForUI,
  canViewOnContentGenius,
  formatPoints,
  getLastStatusUpdate,
  handleAddToList,
  handleRemoveFromList,
  handleUpdateListEntry,
  computeFuelFxVisitorCount,
  getAssetCost,
};
