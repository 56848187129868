// utils
const hasIn = require('lodash/hasIn');

const BASE_FOUNDATION = {
  // foundation plan
  subtext:
    'Full-funnel ROI insights across the MarTech stack for small-to-mid-sized businesses building their initial foundation',
  optimalForValues: {
    monthlyForms: 50,
    avgCalls: 750,
  },
  customer360: true,
  marketingContacts: {
    contacts: 20000,
    emails: 160000,
    value: 200,
  },
  emailMarketing: {
    contacts: 10000,
    pricing: 25,
  },
  bulkUploads: true,
  leadStatuses: true,
  nutshellCRM: {
    seats: 10,
    seatsValue: 490,
    discount: 20,
    type: 'Pro',
  },
  sms: {
    outbound: 150,
    pool: 1500,
    users: 10,
  },
  webChat: true,
  nutshellOnboarding: 4,
  cdpRetention: 10,
  playbookfx: true,
  analyticsfx: true,
  leadEnrichment: {
    standard: true,
    enterprise: false,
    spamFilter: true,
    leadVideos: 250,
  },
  conversationalIntel: 1250,
  funnelsfx: true,
  schedulerfx: {
    enabled: true,
    coHost: true,
    roundRobin: false,
    customDomains: false,
    removeBranding: false,
  },
  dataEnrichment: {
    contact: {
      number: 2000,
      value: 400,
    },
    additionalEnrichment: 0.2,
    B2B: true,
    B2C: true,
    people: false,
    company: true,
    enterprise: false,
    secondaryEmailAppend: true,
    B2CLeads: true,
    B2BLeads: true,
  },
  leadscorer: {
    standard: true,
    pro: false,
  },
  companytrackerfx: {
    standard: true,
  },
  newB2B: {
    monthlyCreditPool: 0,
    companyTrackerPro: 5,
    prospector: 5,
    additionalProspects: false,
  },
  nutshell: {
    crm: true,
    contactCreation: true,
  },
  integrations: {
    crms: {
      support:
        'Nutshell, Salesforce, Hubspot, Microsoft Dynamics, Pipedrive, Zoho CRM and many more!',
      additionalSupport: false,
      supportPercentage: 90,
      syncingToMCFX: true,
      syncingAttributionData: true,
      syncingFormSubmissionsToCRM: 'Nutshell & Salesforce Only',
      syncingAdvancedSalesPipeline: false,
      syncingEnrichmentData: false,
    },
    advertisingPipelines: {
      total: 15,
    },
    additionalMartech: {
      total: 2,
    },
    moreFeatures: {
      newIntegrationfxDataPipelines: true,
      ecommerce: true,
      customerService: true,
      communicationPlatforms: true,
      accounting: false,
      ats: false,
      documentManagement: false,
      proposals: false,
      enterpriseERP: false,
      dataWarehouse: false,
    },
    dataObservability: true,
    pipelineConsulting: false,
  },
  adtechfx: `Available for WebFX clients on Pro & Enterprise Paid Search Plans.`,
  compspy: true,
  localfx: true,
  reviewLocations: 3,
  automatedEmail: true,
  leadnurturefx: true,
  contentAnalytics: true,
  contentGenius: true,
  deeplearningfx: 5,
  personalizeFx: 15,
  personalizedOnboarding: {
    contactsImport: true,
    onboardingCalls: {
      calls: 3,
      additionalHours: 10,
    },
    martechSpecialistConsultations: 1,
    dataArchitechSupport: 4,
    dedicatedMartechExpert: true,
    additionalMartechSupport: true,
  },
  monthlyInvestment: 675,
  existingMonthlyInvestment: 500,

  companyTracking: true,
  cplDashboard: true,
  crmroi: 3,
  legacyContacts: true,
  mostPopular: false,
  supportsLegacy: false,
  martechSpecialist: 3,
  isFoundation: true,
  customerJourney: 250000,
  conversionBasedSmartBidding: `Available to WebFX Paid Search Clients`,
  lookalikeCustomAudiences: `Available to WebFX Paid Search Clients  - Up to a total of 8 audiences`,
  onlineOffflineConversion: `Available to WebFX Paid Social Clients`,
  lookalikeAudienceTargeting: `Available to WebFX Paid Social Clients  - Up to a total of 8 audiences`,
  crmClosedLoop: true,
  crmNewContactCreation: false,
  unidirectionalDataPipelines: true,
  bidirectionalDataPipelines: false,
  integrationsClosedLoop: null,
  dataWarehouse: false,
  companyEnrichment: {
    standard: true,
    enterprise: false,
  },
  leadScoring: false,
  callEnrichment: {
    standard: true,
    enterprise: false,
  },
  nutshellMessages: 20000,
  annualMartechSpecialist: 1,
  dedicatedMcfxSolutionsExpert: false,
  crmDataPipelines: 1,
  martechDataPipelines: 'Up to a total of 3 Unidirection IntegrationsFX Data Pipelines',
  leadScorer: 'Leads scored based on 1st-party data only',
};

/**
 * TODO: This file should not exist, this information belongs in the database
 * Handles appending additional
 * metadata for the given plan
 * @param {integer} planId - db plan id (based on plan db ids which are intended to not change)
 * @returns {object} - plan metadata object
 */
function getPlanMetadata(planId = null) {
  // metadata construct
  const plansMetaDataEntries = {
    1: {
      // ancient plan
      subtext:
        'Outdated plans lacking modern marketing needs originally designed for micro-and-starting businesses at the start of their website and advertising journey',
      customer360: true,
      marketingContacts: {
        contacts: 2500,
        emails: 20000,
        value: 35,
      },
      emailMarketing: {
        contacts: 10000,
        pricing: 25,
      },
      bulkUploads: true,
      leadStatuses: true,
      nutshellCRM: {
        seats: 4,
        seatsValue: 196,
        discount: 20,
        type: 'Pro',
      },
      sms: {
        outbound: 150,
        pool: 600,
        users: 4,
      },
      webChat: true,
      nutshellOnboarding: 4,
      cdpRetention: 3,
      playbookfx: true,
      analyticsfx: true,
      leadEnrichment: {
        standard: true,
        enterprise: false,
        spamFilter: true,
        leadVideos: 25,
      },
      calltrackerfx: {
        numbers: 15,
        callVolume: 750,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: false,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.75,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.06,
      },
      conversationalIntel: 750,
      funnelsfx: true,
      schedulerfx: {
        enabled: true,
        coHost: true,
        roundRobin: false,
        customDomains: false,
        removeBranding: false,
      },
      dataEnrichment: {
        contact: {
          number: 50,
          value: null,
        },
        additionalEnrichment: null,
        B2B: false,
        B2C: true,
        people: false,
        company: true,
        enterprise: false,
        secondaryEmailAppend: true,
        B2CLeads: true,
        B2BLeads: true,
      },
      leadscorer: {
        standard: true,
        pro: false,
      },
      companytrackerfx: {
        standard: true,
      },
      newB2B: {
        monthlyCreditPool: 0,
        companyTrackerPro: 5,
        prospector: 5,
        additionalProspects: false,
      },
      nutshell: {
        crm: true,
        contactCreation: true,
      },
      integrations: {
        crms: {
          support:
            'Nutshell, Salesforce, Hubspot, Microsoft Dynamics, Pipedrive, Zoho CRM and many more!',
          additionalSupport: false,
          supportPercentage: 90,
          syncingToMCFX: true,
          syncingAttributionData: true,
          syncingFormSubmissionsToCRM: false,
          syncingAdvancedSalesPipeline: false,
          syncingEnrichmentData: false,
        },
        advertisingPipelines: null,
        additionalMartech: null,
        moreFeatures: {
          newIntegrationfxDataPipelines: false,
          ecommerce: false,
          customerService: false,
          communicationPlatforms: false,
          accounting: false,
          ats: false,
          documentManagement: false,
          proposals: false,
          enterpriseERP: false,
          dataWarehouse: false,
        },
        dataObservability: true,
        pipelineConsulting: false,
      },
      adtechfx: `Available for WebFX clients on Pro & Enterprise Paid Search Plans.`,
      compspy: true,
      localfx: true,
      reviewLocations: 1,
      automatedEmail: true,
      leadnurturefx: true,
      contentAnalytics: true,
      contentGenius: true,
      deeplearningfx: 0,
      personalizeFx: 0,
      personalizedOnboarding: {
        contactsImport: true,
        onboardingCalls: null,
        martechSpecialistConsultations: false,
        dataArchitechSupport: false,
        dedicatedMartechExpert: false,
        additionalMartechSupport: true,
      },
      monthlyInvestment: 150,
      existingMonthlyInvestment: null,

      companyTracking: true,
      cplDashboard: true,
      crmroi: 3,
      legacyContacts: false,
      mostPopular: false,
      supportsLegacy: true,
      martechSpecialist: null,
      isAncient: true,
      customerJourney: null,
      conversionBasedSmartBidding: `Available to WebFX Paid Search Clients (Up to 1 platform)`,
      lookalikeCustomAudiences: null,
      onlineOffflineConversion: `Available to WebFX Paid Social Clients (Up to 1 platform)`,
      lookalikeAudienceTargeting: null,
      crmClosedLoop: false,
      crmNewContactCreation: false,
      unidirectionalDataPipelines: false,
      bidirectionalDataPipelines: false,
      integrationsClosedLoop: null,
      dataWarehouse: false,
      companyEnrichment: {
        standard: false,
        enterprise: false,
      },

      leadScoring: false,
      callEnrichment: {
        standard: true,
        enterprise: false,
      },
      annualMartechSpecialist: null,
      dedicatedMcfxSolutionsExpert: false,
      crmDataPipelines: 1,
      martechDataPipelines: null,
    },
    2: {
      // lite plan
      subtext: 'Lite ROI tracking for companies with minimal digital marketing investments',
      customer360: true,
      marketingContacts: {
        contacts: 2500,
        emails: 20000,
        value: 35,
      },
      emailMarketing: {
        contacts: 10000,
        pricing: 25,
      },
      bulkUploads: true,
      leadStatuses: true,
      nutshellCRM: {
        seats: 4,
        seatsValue: 196,
        discount: 20,
        type: 'Pro',
      },
      sms: {
        outbound: 150,
        pool: 600,
        users: 4,
      },
      webChat: true,
      nutshellOnboarding: 4,
      cdpRetention: 3,
      playbookfx: true,
      analyticsfx: true,
      leadEnrichment: {
        standard: true,
        enterprise: false,
        spamFilter: true,
        leadVideos: 50,
      },
      calltrackerfx: {
        numbers: 15,
        callVolume: 750,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: false,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.75,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.06,
      },
      conversationalIntel: 750,
      funnelsfx: true,
      schedulerfx: {
        enabled: true,
        coHost: true,
        roundRobin: false,
        customDomains: false,
        removeBranding: false,
      },
      dataEnrichment: {
        contact: {
          number: 50,
          value: null,
        },
        additionalEnrichment: null,
        B2B: true,
        B2C: true,
        people: false,
        company: true,
        enterprise: false,
        secondaryEmailAppend: true,
        B2CLeads: true,
        B2BLeads: true,
      },
      leadscorer: {
        standard: true,
        pro: false,
      },
      companytrackerfx: {
        standard: true,
      },
      newB2B: {
        monthlyCreditPool: 0,
        companyTrackerPro: 5,
        prospector: 5,
        additionalProspects: false,
      },
      nutshell: {
        crm: true,
        contactCreation: true,
      },
      integrations: {
        crms: {
          support:
            'Nutshell, Salesforce, Hubspot, Microsoft Dynamics, Pipedrive, Zoho CRM and many more!',
          additionalSupport: false,
          supportPercentage: 90,
          syncingToMCFX: true,
          syncingAttributionData: true,
          syncingFormSubmissionsToCRM: false,
          syncingAdvancedSalesPipeline: false,
          syncingEnrichmentData: false,
        },
        advertisingPipelines: null,
        additionalMartech: null,
        moreFeatures: {
          newIntegrationfxDataPipelines: false,
          ecommerce: false,
          customerService: false,
          communicationPlatforms: false,
          accounting: false,
          ats: false,
          documentManagement: false,
          proposals: false,
          enterpriseERP: false,
          dataWarehouse: false,
        },
        dataObservability: true,
        pipelineConsulting: false,
      },
      adtechfx: `Available for WebFX clients on Pro & Enterprise Paid Search Plans.`,
      compspy: true,
      localfx: true,
      reviewLocations: 1,
      automatedEmail: true,
      leadnurturefx: true,
      contentAnalytics: true,
      contentGenius: true,
      deeplearningfx: 0,
      personalizeFx: 3,
      personalizedOnboarding: {
        contactsImport: true,
        onboardingCalls: null,
        martechSpecialistConsultations: false,
        dataArchitechSupport: false,
        dedicatedMartechExpert: false,
        additionalMartechSupport: true,
      },
      monthlyInvestment: 300,
      existingMonthlyInvestment: 200,

      companyTracking: true,
      cplDashboard: true,
      crmroi: 3,
      legacyContacts: true,
      mostPopular: false,
      supportsLegacy: false,
      martechSpecialist: 'Account Manager Provides Onboarding',
      isLite: true,
      customerJourney: 250000,
      conversionBasedSmartBidding: `Available to WebFX Paid Search Clients (Up to 1 platform)`,
      lookalikeCustomAudiences: null,
      onlineOffflineConversion: `Available to WebFX Paid Social Clients (Up to 1 platform)`,
      lookalikeAudienceTargeting: null,
      crmClosedLoop: false,
      crmNewContactCreation: false,
      unidirectionalDataPipelines: false,
      bidirectionalDataPipelines: false,
      integrationsClosedLoop: null,
      dataWarehouse: false,
      companyEnrichment: {
        standard: false,
        enterprise: false,
      },
      leadScoring: false,
      callEnrichment: {
        standard: true,
        enterprise: false,
      },
      annualMartechSpecialist: null,
      dedicatedMcfxSolutionsExpert: false,
      crmDataPipelines: 1,
      martechDataPipelines: null,
    },
    3: {
      ...BASE_FOUNDATION,
      calltrackerfx: {
        numbers: 25,
        callVolume: 1250,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.75,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.06,
      },
    },
    7: {
      ...BASE_FOUNDATION,
      calltrackerfx: {
        numbers: 25,
        callVolume: 2500,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.75,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.06,
      },
      existingMonthlyInvestment: 1275,
    },
    8: {
      ...BASE_FOUNDATION,
      calltrackerfx: {
        numbers: 75,
        callVolume: 5000,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.75,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.06,
      },
      existingMonthlyInvestment: 1425,
    },
    9: {
      ...BASE_FOUNDATION,
      calltrackerfx: {
        numbers: 125,
        callVolume: 8000,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.75,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.06,
      },
      existingMonthlyInvestment: 1650,
    },
    4: {
      // professional plan
      subtext:
        'Enhanced data enrichment & integrations delivering data-powered performance lift for growth-focused companies looking to ignite their sales and revenue',
      optimalForValues: {
        monthlyForms: 200,
        avgCalls: 1500,
      },
      customer360: true,
      marketingContacts: {
        contacts: 50000,
        emails: 400000,
        value: 320,
      },
      emailMarketing: {
        contacts: 10000,
        pricing: 25,
      },
      bulkUploads: true,
      leadStatuses: true,
      nutshellCRM: {
        seats: 20,
        seatsValue: 1340,
        discount: 30,
        type: 'Enterprise',
      },
      sms: {
        outbound: 150,
        pool: 3000,
        users: 20,
      },
      webChat: true,
      nutshellOnboarding: 4,
      cdpRetention: 10,
      playbookfx: true,
      analyticsfx: true,
      leadEnrichment: {
        standard: true,
        enterprise: false,
        spamFilter: true,
        leadVideos: 500,
      },
      calltrackerfx: {
        numbers: 50,
        callVolume: 2500,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.25,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.045,
      },
      conversationalIntel: 2500,
      funnelsfx: true,
      schedulerfx: {
        enabled: true,
        coHost: true,
        roundRobin: true,
        customDomains: true,
        removeBranding: true,
      },
      dataEnrichment: {
        contact: {
          number: 5000,
          value: 750,
        },
        additionalEnrichment: 0.15,
        B2B: true,
        B2C: true,
        people: true,
        company: true,
        enterprise: false,
        secondaryEmailAppend: true,
        B2CLeads: true,
        B2BLeads: true,
      },
      leadscorer: {
        standard: true,
        pro: true,
      },
      companytrackerfx: {
        standard: true,
      },
      newB2B: {
        monthlyCreditPool: {
          total: 300,
          value: 150,
        },
        companyTrackerPro: -1,
        prospector: -1,
        additionalProspects: 0.5,
      },
      nutshell: {
        crm: true,
        contactCreation: true,
      },
      integrations: {
        crms: {
          support:
            'Nutshell, Salesforce, Hubspot, Microsoft Dynamics, Zoho CRM, Webhook, Pipedrive and many more!',
          additionalSupport: false,
          supportPercentage: 95,
          syncingToMCFX: true,
          syncingAttributionData: true,
          syncingFormSubmissionsToCRM: true,
          syncingAdvancedSalesPipeline: true,
          syncingEnrichmentData: true,
        },
        advertisingPipelines: {
          total: 30,
        },
        additionalMartech: {
          total: 4,
        },
        moreFeatures: {
          newIntegrationfxDataPipelines: true,
          ecommerce: true,
          customerService: true,
          communicationPlatforms: true,
          accounting: true,
          ats: true,
          documentManagement: true,
          proposals: true,
          enterpriseERP: false,
          dataWarehouse: false,
        },
        dataObservability: true,
        pipelineConsulting: false,
      },
      adtechfx: `Available for WebFX clients on Pro & Enterprise Paid Search Plans.`,
      compspy: true,
      localfx: true,
      reviewLocations: 5,
      automatedEmail: true,
      leadnurturefx: true,
      contentAnalytics: true,
      contentGenius: true,
      deeplearningfx: 10,
      personalizeFx: 30,
      personalizedOnboarding: {
        contactsImport: true,
        onboardingCalls: {
          calls: 3,
          additionalHours: 15,
        },
        martechSpecialistConsultations: 2,
        dataArchitechSupport: 6,
        dedicatedMartechExpert: true,
        additionalMartechSupport: true,
      },
      monthlyInvestment: 1175,
      existingMonthlyInvestment: 975,

      companyTracking: true,
      cplDashboard: true,
      crmroi: 5,
      legacyContacts: true,
      mostPopular: true,
      supportsLegacy: false,
      martechSpecialist: 3,
      isProfessional: true,
      customerJourney: 500000,
      conversionBasedSmartBidding: `Available to WebFX Paid Search Clients`,
      lookalikeCustomAudiences: `Available to WebFX Paid Search Clients  - Up to a total of 15 audiences`,
      onlineOffflineConversion: `Available to WebFX Paid Social Clients`,
      lookalikeAudienceTargeting: `Available to WebFX Paid Social Clients  - Up to a total of 15 audiences`,
      crmClosedLoop: true,
      crmNewContactCreation: true,
      unidirectionalDataPipelines: true,
      bidirectionalDataPipelines: true,
      integrationsClosedLoop: `Excludes Enterprise ERP Integrations`,
      dataWarehouse: false,
      companyEnrichment: {
        standard: true,
        enterprise: true,
      },
      leadScoring: true,
      callEnrichment: {
        standard: true,
        enterprise: true,
      },
      nutshellMessages: 50000,
      annualMartechSpecialist: 2,
      dedicatedMcfxSolutionsExpert: false,
      crmDataPipelines: 1,
      martechDataPipelines:
        'Up to a total of 6 Unidirection or Bidirectional IntegrationsFX Data Pipelines',
      leadScorer: 'Leads scored based on 1st-party and 3rd-party enrichment data',
    },
    5: {
      // Innovator VIP Plan
      subtext:
        'Fuels data-driven growth with enterprise-level capacity of tracking and contacts enrichment for high-volume websites',
      customer360: true,
      marketingContacts: {
        contacts: 250000,
        emails: 2000000,
        value: 1360,
      },
      emailMarketing: {
        contacts: 10000,
        pricing: 25,
      },
      bulkUploads: true,
      leadStatuses: true,
      nutshellCRM: {
        seats: -1,
        seatsValue: -1,
        discount: -1,
      },
      sms: {
        outbound: 150,
        pool: -1,
        users: -1,
      },
      webChat: true,
      nutshellOnboarding: 12,
      cdpRetention: -1,
      playbookfx: true,
      analyticsfx: true,
      leadEnrichment: {
        standard: true,
        enterprise: false,
        spamFilter: true,
        leadVideos: 5000,
      },
      calltrackerfx: {
        numbers: 150,
        callVolume: 10000,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 1.5,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.02,
      },
      googleAdsCallTrackingPool: true,
      conversationalIntel: 10000,
      funnelsfx: true,
      schedulerfx: {
        enabled: true,
        coHost: true,
        roundRobin: true,
        customDomains: true,
        removeBranding: true,
      },
      dataEnrichment: {
        contact: {
          number: 40000,
          value: 4000,
        },
        additionalEnrichment: 0.1,
        B2B: true,
        B2C: true,
        people: true,
        company: true,
        enterprise: true,
        secondaryEmailAppend: true,
        B2CLeads: true,
        B2BLeads: true,
      },
      leadscorer: {
        standard: true,
        pro: true,
      },
      companytrackerfx: {
        standard: true,
      },
      newB2B: {
        monthlyCreditPool: {
          total: 2000,
          value: 800,
        },
        companyTrackerPro: -1,
        prospector: -1,
        additionalProspects: 0.4,
      },
      nutshell: {
        crm: true,
        contactCreation: true,
      },
      integrations: {
        crms: {
          support:
            'Nutshell, Salesforce, Hubspot, Microsoft Dynamics, Zoho CRM, Webhook, Pipedrive',
          additionalSupport: 'Any custom API Integration!',
          supportPercentage: 98,
          syncingToMCFX: true,
          syncingAttributionData: true,
          syncingFormSubmissionsToCRM: true,
          syncingAdvancedSalesPipeline: true,
          syncingEnrichmentData: true,
        },
        advertisingPipelines: {
          total: 100,
        },
        additionalMartech: {
          total: 15,
        },
        moreFeatures: {
          newIntegrationfxDataPipelines: true,
          ecommerce: true,
          customerService: true,
          communicationPlatforms: true,
          accounting: true,
          ats: true,
          documentManagement: true,
          proposals: true,
          enterpriseERP: true,
          dataWarehouse: true,
        },
        dataObservability: true,
        pipelineConsulting: 50,
      },
      adtechfx: `Available for WebFX clients on Pro & Enterprise Paid Search Plans.`,
      compspy: true,
      localfx: true,
      reviewLocations: 25,
      automatedEmail: true,
      leadnurturefx: true,
      contentAnalytics: true,
      contentGenius: true,
      deeplearningfx: -1,
      personalizeFx: -1,
      personalizedOnboarding: {
        contactsImport: true,
        onboardingCalls: {
          calls: 4,
          additionalHours: 30,
        },
        martechSpecialistConsultations: 4,
        dataArchitechSupport: 20,
        dedicatedMartechExpert: true,
        additionalMartechSupport: true,
      },
      monthlyInvestment: 4975,
      existingMonthlyInvestment: 4475,

      companyTracking: true,
      cplDashboard: true,
      crmroi: -1,
      legacyContacts: true,
      mostPopular: false,
      supportsLegacy: false,
      martechSpecialist: 4,
      isEnterprise: true,
      customerJourney: 1000000,
      conversionBasedSmartBidding: `Available to WebFX Paid Search Clients`,
      lookalikeCustomAudiences: `Available to WebFX Paid Search Clients  - Up to a total of 30 audiences`,
      onlineOffflineConversion: `Available to WebFX Paid Social Clients`,
      lookalikeAudienceTargeting: `Available to WebFX Paid Social Clients  - Up to a total of 30 audiences`,
      crmClosedLoop: true,
      crmNewContactCreation: true,
      unidirectionalDataPipelines: true,
      bidirectionalDataPipelines: true,
      integrationsClosedLoop: `Yes, including Enterprise ERPs such as Oracle E-Business Suite, SAP, ADP Wisely, and Microsoft Dynamics Business Central`,
      dataWarehouse: true,
      companyEnrichment: {
        standard: true,
        enterprise: true,
      },
      leadScoring: true,
      callEnrichment: {
        standard: true,
        enterprise: true,
      },
      nutshellMessages: 50000,
      annualMartechSpecialist: 4,
      dedicatedMcfxSolutionsExpert: true,
      crmDataPipelines: 1,
      martechDataPipelines:
        'Up to a total of 10 Unidirection or Bidirectional IntegrationsFX Data Pipelines',
      leadScorer: 'Leads scored based on 1st-party and 3rd-party enrichment data',
    },
    6: {
      // Innovator plan
      subtext:
        'Helps more advanced small-to-mid-sized companies attack their space and gain a competitive advantage through data acquisition and marketing intelligence',
      optimalForValues: {
        monthlyForms: 600,
        avgCalls: 3000,
      },
      customer360: true,
      marketingContacts: {
        contacts: 100000,
        emails: 800000,
        value: 710,
      },
      emailMarketing: {
        contacts: 10000,
        pricing: 25,
      },
      bulkUploads: true,
      leadStatuses: true,
      nutshellCRM: {
        seats: 40,
        seatsValue: 2680,
        discount: 30,
        type: 'Enterprise',
      },
      sms: {
        outbound: 150,
        pool: 6000,
        users: 40,
      },
      webChat: true,
      nutshellOnboarding: 6,
      cdpRetention: -1,
      playbookfx: true,
      analyticsfx: true,
      leadEnrichment: {
        standard: true,
        enterprise: false,
        spamFilter: true,
        leadVideos: 1000,
      },
      calltrackerfx: {
        numbers: 75,
        callVolume: 5000,
        channelLevelTracking: true,
        smsForwarding: true,
        visitorLevelTracking: true,
        standardCallEnrichment: true,
        googleAdsCallTrackingPool: true,
        additionalNumbers: 2.0,
        automatedFallBackSharedPooling: true,
        inboundCaller: true,
        planOverages: 0.03,
      },
      conversationalIntel: 5000,
      funnelsfx: true,
      schedulerfx: {
        enabled: true,
        coHost: true,
        roundRobin: true,
        customDomains: true,
        removeBranding: true,
      },
      dataEnrichment: {
        contact: {
          number: 15000,
          value: 1950,
        },
        additionalEnrichment: 0.13,
        B2B: true,
        B2C: true,
        people: true,
        company: true,
        enterprise: false,
        secondaryEmailAppend: true,
        B2CLeads: true,
        B2BLeads: true,
      },
      leadscorer: {
        standard: true,
        pro: true,
      },
      companytrackerfx: {
        standard: true,
      },
      newB2B: {
        monthlyCreditPool: {
          total: 800,
          value: 360,
        },
        companyTrackerPro: -1,
        prospector: -1,
        additionalProspects: 0.45,
      },
      nutshell: {
        crm: true,
        contactCreation: true,
      },
      integrations: {
        crms: {
          support:
            'Nutshell, Salesforce, Hubspot, Microsoft Dynamics, Zoho CRM, Webhook, Pipedrive',
          additionalSupport: 'Any custom API Integration!',
          supportPercentage: 97,
          syncingToMCFX: true,
          syncingAttributionData: true,
          syncingFormSubmissionsToCRM: true,
          syncingAdvancedSalesPipeline: true,
          syncingEnrichmentData: true,
        },
        advertisingPipelines: {
          total: 50,
        },
        additionalMartech: {
          total: 7,
        },
        moreFeatures: {
          newIntegrationfxDataPipelines: true,
          ecommerce: true,
          customerService: true,
          communicationPlatforms: true,
          accounting: true,
          ats: true,
          documentManagement: true,
          proposals: true,
          enterpriseERP: false,
          dataWarehouse: false,
        },
        dataObservability: true,
        pipelineConsulting: 25,
      },
      adtechfx: `Available for WebFX clients on Pro & Enterprise Paid Search Plans.`,
      compspy: true,
      localfx: true,
      reviewLocations: 10,
      automatedEmail: true,
      leadnurturefx: true,
      contentAnalytics: true,
      contentGenius: true,
      deeplearningfx: 25,
      personalizeFx: 60,
      personalizedOnboarding: {
        contactsImport: true,
        onboardingCalls: {
          calls: 4,
          additionalHours: 20,
        },
        martechSpecialistConsultations: 4,
        dataArchitechSupport: 12,
        dedicatedMartechExpert: true,
        additionalMartechSupport: true,
      },
      monthlyInvestment: 2500,
      existingMonthlyInvestment: 2250,

      companyTracking: true,
      cplDashboard: true,
      crmroi: -1,
      legacyContacts: true,
      mostPopular: false,
      supportsLegacy: false,
      martechSpecialist: 4,
      isEnterprise: true,
      customerJourney: 1000000,
      conversionBasedSmartBidding: `Available to WebFX Paid Search Clients`,
      lookalikeCustomAudiences: `Available to WebFX Paid Search Clients  - Up to a total of 30 audiences`,
      onlineOffflineConversion: `Available to WebFX Paid Social Clients`,
      lookalikeAudienceTargeting: `Available to WebFX Paid Social Clients  - Up to a total of 30 audiences`,
      crmClosedLoop: true,
      crmNewContactCreation: true,
      unidirectionalDataPipelines: true,
      bidirectionalDataPipelines: true,
      integrationsClosedLoop: `Yes, including Enterprise ERPs such as Oracle E-Business Suite, SAP, ADP Wisely, and Microsoft Dynamics Business Central`,
      dataWarehouse: true,
      companyEnrichment: {
        standard: true,
        enterprise: true,
      },
      leadScoring: true,
      callEnrichment: {
        standard: true,
        enterprise: true,
      },
      nutshellMessages: 50000,
      annualMartechSpecialist: 4,
      dedicatedMcfxSolutionsExpert: true,
      crmDataPipelines: 1,
      martechDataPipelines:
        'Up to a total of 10 Unidirection or Bidirectional IntegrationsFX Data Pipelines',
      leadScorer: 'Leads scored based on 1st-party and 3rd-party enrichment data',
    },
  };

  // pull plan meta
  if (hasIn(plansMetaDataEntries, planId)) {
    return plansMetaDataEntries[planId];
  }

  // default
  return {};
}

module.exports = getPlanMetadata;
