const planToLimit = {
  1: 5,
  2: 5,
  3: 5,
  4: 300,
  5: 2000,
  6: 800,
  7: 5,
  8: 5,
  9: 5,
};

module.exports = planToLimit;
