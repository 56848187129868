import { Snackbar, Typography, Svg, FormikInput, Button } from '@webfx/core-web';
import { usePageView, useDocumentTitle } from '@webfx/web-hooks';
import { Formik, Form } from 'formik';
import MarketingCloud from '../../assets/marketingcloudfx.svg';
import { usePasswordConfirmation } from '../../hooks/usePasswordConfirmation';

import styles from './ConfirmPassword.module.css';

export function ConfirmPassword() {
  usePageView();
  useDocumentTitle('Confirm Password | RevenueCloudFX');
  const confirm = usePasswordConfirmation();

  return (
    <div className={styles.page}>
      <div className={styles.wrap}>
        <Svg className="mx-auto mb-4" src={MarketingCloud} style={{ width: 320 }} />

        <Typography component="h1" variant="h1" className="text-center" gutterBottom>
          Create a new password, to reset your account login.
        </Typography>
        <Formik {...confirm.form}>
          {() => (
            <Form>
              <FormikInput type="password" name="password" label="Password" bottomGutter />
              <FormikInput type="password" name="confirmPassword" label="Confirm password" />
              <div className={styles.btnWrap}>
                <Button
                  type="submit"
                  variant="primary"
                  text="Reset password"
                  block
                  uppercase
                  isLoading={confirm.isSubmitting}
                  disabled={confirm.isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Snackbar />
    </div>
  );
}
