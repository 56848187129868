/**
 * Check if the given URL is a Google Doc link.
 * @param {string} url
 * @returns {boolean}
 */
const isGoogleDocLink = (url) => {
  const googleDocRegex =
    /^https:\/\/docs\.google\.com\/document\/d\/[a-zA-Z0-9_-]+\/?(edit)?(\?.*)?$/;
  return googleDocRegex.test(url);
};

module.exports = isGoogleDocLink;
