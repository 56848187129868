const { omit, pick, isEmpty } = require('lodash');
const existsFields = ['requireEmail', 'requirePhone'];
const excludesFields = [
  'excludekfCompanyLocation',
  'excludekfCompanyNAICSCode',
  'excludelocation',
  'excludeindustry',
];
const CONFIG_FIELDS_TO_QUERY_FIELDS = {
  jobTitle: 'jobTitle.exact',
  jobField: 'jobDepartment',
  managementLevel: 'jobTitleLevels',
  requireEmail: 'emails',
  requirePhone: 'phoneNumbers',
  education: 'education.school.name',
};

const icpArrayFields = [
  'jobCompanySize',
  'revenue',
  'location',
  'industry',
  'skills',
  'kfCompanyEmployees',
  'kfCompanyIndustries',
  'kfCompanyLocation',
  'kfCompanyRevenue',
  'kfCompanyNAICSCode',
];

const productFilters = {
  cotfx: ['jobField', 'jobDepartment', 'jobTitleLevels', 'jobTitle', 'managementLevel'],
  prospector: [
    'kfCompanyEmployees',
    'kfCompanyIndustries',
    'kfCompanyLocation',
    'kfCompanyRevenue',
    'kfCompanyNAICSCode',
  ],
  adtech: [
    'jobTitle.exact',
    'jobTitleRole',
    'jobDepartment',
    'jobField',
    'jobTitleLevels',
    'kfCompanyNAICSCode',
    'kfCompanyRevenue',
    'kfCompanyEmployees',
    'kfCompanyLocation',
    'excludes',
  ],
};

/**
 * @typedef {{
 *   cotfx: string,
 *   adtech: string,
 *   prospector: string,
 * }} PRODUCTFILTERS
 */

/**
 *
 * @param {object} icp - the icp object
 * @param {keyof PRODUCTFILTERS} product
 * @param {boolean} evaluateMetaFields - whether to evaluate meta fields (['hiddenCompanies', 'campaignCompanies'])
 */
const transformICPToFilters = (icp = {}, product, evaluateMetaFields = false) => {
  if (isEmpty(icp)) {
    return {};
  }
  const icpCopy = { ...icp };
  if (icp.configuredBy === 'jobTitle') {
    delete icpCopy.managementLevel;
    delete icpCopy.jobField;
  } else {
    delete icpCopy.jobTitle;
  }

  const filters = {};
  if (evaluateMetaFields) {
    const { hiddenCompanies = [], campaignCompanies = [], jobCompanyId = [] } = icpCopy;
    if (hiddenCompanies.length) {
      filters.jobCompanyId = {
        $nin: hiddenCompanies.slice(0, 100),
      };
    }
    if (campaignCompanies.length || jobCompanyId.length) {
      filters.jobCompanyId = {
        ...(filters.jobCompanyId || {}),
        $in: [...campaignCompanies, ...jobCompanyId].slice(0, 100),
      };
    }
    delete icpCopy.jobCompanyId;
  }
  delete icpCopy.hiddenCompanies;
  delete icpCopy.campaignCompanies;

  for (const [key, val] of Object.entries(icpCopy)) {
    if (Array.isArray(val) && val.length) {
      if (icpArrayFields.includes(key)) {
        filters[CONFIG_FIELDS_TO_QUERY_FIELDS[key] ?? key] = val;
      } else {
        filters[CONFIG_FIELDS_TO_QUERY_FIELDS[key] ?? key] = val.map((v) => {
          const value = typeof v === 'string' ? v.toLowerCase() : v;
          return {
            label: value,
            value: key === 'jobField' ? value?.replace(' ', '_') : value,
          };
        });
      }
    } else if (typeof val === 'boolean' && val) {
      if (existsFields.includes(key)) {
        if (filters['$exists']) {
          filters['$exists'].push(CONFIG_FIELDS_TO_QUERY_FIELDS[key] ?? key);
        } else {
          filters['$exists'] = [CONFIG_FIELDS_TO_QUERY_FIELDS[key] ?? key];
        }
      } else if (excludesFields.includes(key)) {
        const fieldToExclude = (CONFIG_FIELDS_TO_QUERY_FIELDS[key] ?? key).replace('exclude', '');
        if (filters['excludes']) {
          filters['excludes'].push(fieldToExclude);
        } else {
          filters['excludes'] = [fieldToExclude];
        }
      }
    }
  }
  if (!product) {
    return filters;
  }
  if (!productFilters[product]) {
    throw new Error(`Unknown product: ${product}`);
  }
  if (product === 'prospector') {
    return omit(filters, productFilters['prospector']);
  }
  return pick(filters, productFilters[product]);
};

module.exports = transformICPToFilters;
