const facets = require('./facets');
const {
  emailMarketingAddons,
  adtechfxAddons,
  outdatedPlans,
  emfxOveragesAddons,
} = require('./get-plan-constants');
const mcfxBlockedIndustries = require('./get-mcfx-blocked-industries');
const campaignUrlGeneratorTemplates = require('./campaign-url-generator-templates.js');
const industriesJSON = require('./industries.json');
const industriesDedupedJSON = require('./industries-deduped.json');
const enrichCreditMapping = require('./enirch-credit-mapping');
const reportServiceData = require('./report-service-data');
const educationLevels = require('./education-levels');

module.exports = {
  facets,
  emailMarketingAddons,
  adtechfxAddons,
  mcfxBlockedIndustries,
  outdatedPlans,
  campaignUrlGeneratorTemplates,
  industriesJSON,
  industriesDedupedJSON,
  enrichCreditMapping,
  reportServiceData,
  emfxOveragesAddons,
  educationLevels,
};
