/**
 * Brain-dump:
 *
 * This utility is to generate plan id trees where a client
 * could belong to (Main, DealerFx, Ecom and Non-client) categories.
 *
 * So for each category, comes an itemCode specific to each respective plan.
 * Once a plan Id and subscriptions are given a plan lever object will be returned
 * which contains the itemCodes usable by the other respective plans for upgrades
 *
 * NOTE: this uses the plan based DB ids
 * 1 => ancient
 * 2 => lite
 * 3 => foundation
 * 4 => professional
 * 5 => innovator vip (formerly enterprise)
 * 6 => innovator
 * 7 => essential
 * 8 => enhanced
 * 9 => premier
 *
 * Handles generating planID trees mapping each plan to its parent based on itemCodes
 *
 * @param {integer} planId
 * @param {array} subscriptions
 * @param {boolean} planComparisonFunction - used to return itemCodes for all plans that pass this check when compared to the given planId. If not provided, this function returns just the given planId's itemCodes.
 * @returns {object} - object containing each respective plan and its lever based itemCode
 */
const getPlanLevers = (planId = null, subscriptions = [], planComparisonFunction) => {
  // lever name to sku
  const leverToSku = {
    main: {
      1: 'ITEM-842',
      2: 'ITEM-843',
      3: 'ITEM-647',
      4: 'ITEM-648',
      5: 'ITEM-649',
      6: 'ITEM-1032',
      // 7: 'ITEM-5001',
      // 8: 'ITEM-5002',
      // 9: 'ITEM-5003',
    },
    dealerFx: {
      1: 'ITEM-871',
      3: 'ITEM-872',
      4: 'ITEM-873',
      5: 'ITEM-874',
      6: 'ITEM-1034',
    },
    nonClient: {
      3: 'ITEM-844',
      4: 'ITEM-845',
      5: 'ITEM-846',
    },
  };

  const processEmptyResponse = () => {
    let leverEntries = {};

    if (leverToSku?.main) {
      if (planComparisonFunction) {
        leverEntries = { ...leverToSku.main };
        if (leverEntries[planId]) {
          delete leverEntries[planId];
        }
      } else {
        if (leverToSku.main[planId]) {
          leverEntries = leverToSku.main[planId];
        }
      }
    }

    return leverEntries;
  };

  if (!planId || !Array.isArray(subscriptions) || subscriptions.length === 0) {
    return processEmptyResponse();
  }

  // grab all item codes
  const subscriptionItemCodes = subscriptions.reduce((accum, value) => {
    if (value?.itemCode) {
      accum.push(value.itemCode);
    }
    return accum;
  }, []);

  // init var
  let planLever;

  // iterate on item codes and grab lever
  planLever = subscriptionItemCodes.reduce((accum, itemCode) => {
    let result = {};

    // eslint-disable-next-line no-unused-vars -- we only need the value
    for (const [_, value] of Object.entries(leverToSku)) {
      if (itemCode === value[planId]) {
        if (planComparisonFunction) {
          Object.entries(value).forEach(([lKey, lValue]) => {
            if (planComparisonFunction(lKey, planId)) {
              result[lKey] = lValue;
            }
          });
        } else {
          result = value[planId];
        }
        break;
      }
    }

    accum = result;
    return accum;
  }, {});

  if (Object.keys(planLever).length === 0) {
    return processEmptyResponse();
  }

  return planLever;
};

module.exports = getPlanLevers;
