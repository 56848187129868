const isEmpty = require('lodash/isEmpty');

// Defaults
const defaults = {
  sources: { adtech: 'adtechfx' },
  mediums: { adtech: 'cpm', organic: 'organic', social: 'social' },
  contents: {
    adtech: `Specific ad, if applicable`,
    ads: `Type of content you're promoting or specific ad, for example "LAL" or "INT"`,
    location: 'Specific location',
    linkPlacement: `The specific button or anchor text where you're placing the link`,
    autoTagged: `This is auto-tagged in Buffer and not customizable. If adding a link manually, use this to identify the post and/or post content if applicable.`,
  },
  campaigns: {
    adtech: (keyword) =>
      `Must contain "${keyword}". Must exactly match the campaign name in The Trade Desk`,
    promotional: `The campaign, sale, service, or product you're promoting, such as "summer-sale" or "door-repair"`,
    ads: (keyword) => `Campaign name, for example "${keyword}"`,
    organic: `Don't forget to auto-tag your organic links following these guidelines in the UTM settings in Buffer.`,
  },
};

/**
 * A list of supported Campaign URL generator templates
 * @param {Array} newData
 * @param {object} styles
 * @returns {Array<object>} - array of template objects
 */
const campaignUrlGeneratorTemplates = (newData = [], styles = {}) => {
  const StaticTemplates = [
    {
      templateId: 'gmb',
      name: 'Local - GMB',
      source: 'google',
      medium: defaults.mediums.organic,
      campaign: 'gmb',
      descriptions: {
        content: defaults.contents.location,
      },
    },
    {
      templateId: 'gmb_post',
      name: 'Local - GMB Post',
      source: 'google',
      medium: defaults.mediums.organic,
      campaign: 'gmb-post',
      descriptions: {
        content: 'Topic of post or specific location',
      },
    },
    {
      templateId: 'bing_places',
      name: 'Local - Bing Places',
      source: 'bing',
      medium: defaults.mediums.organic,
      campaign: 'bing-places',
      descriptions: {
        content: defaults.contents.location,
      },
    },
    {
      templateId: 'localfx',
      name: 'LocalFX',
      source: 'localfx',
      medium: defaults.mediums.organic,
      campaign: 'localfx',
      descriptions: {
        content: defaults.contents.location,
      },
      channelId: 'all_local',
    },
    {
      templateId: 'email',
      name: 'Email Marketing',
      medium: 'email',
      descriptions: {
        source: 'Type of email marketing, such as "newsletter"',
        campaign: defaults.campaigns.promotional,
        content: defaults.contents.linkPlacement,
      },
      channelId: 'email_marketing',
    },
    {
      templateId: 'leadnurturefx',
      name: 'LeadNurtureFX',
      source: 'leadnurturefx',
      medium: 'email',
      descriptions: {
        campaign: defaults.campaigns.promotional,
        content: defaults.contents.linkPlacement,
      },
      channelId: 'leadnurturefx',
    },
    {
      templateId: 'facebook_ads',
      name: 'Facebook Ads',
      source: 'facebook',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('fb-leadgen'),
        content: defaults.contents.ads,
      },
      channelId: 'fb_ig_ads',
    },
    {
      templateId: 'pinterest_ads',
      name: 'Pinterest Ads',
      source: 'pinterest',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('fb-leadgen'),
        content: defaults.contents.ads,
      },
    },
    {
      templateId: 'reddit_ads',
      name: 'Reddit Ads',
      source: 'reddit',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('red-leadgen'),
        content: defaults.contents.ads,
      },
    },
    {
      templateId: 'instagram_ads',
      name: 'Instagram Ads',
      source: 'instagram',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('ig-leadgen'),
        content: defaults.contents.ads,
      },
    },
    {
      templateId: 'linkedIn_ads',
      name: 'LinkedIn Ads',
      source: 'linkedin',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('li-leadgen'),
        content: defaults.contents.ads,
      },
      channelId: 'linkedin_ads',
    },
    {
      templateId: 'tiktok_ads',
      name: 'TikTok Ads',
      source: 'tiktok',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('tt-leadgen'),
        content: defaults.contents.ads,
      },
    },
    {
      templateId: 'twitter_ads',
      name: 'Twitter Ads',
      source: 'twitter',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('tw-leadgen'),
        content: defaults.contents.ads,
      },
    },
    {
      templateId: 'facebook_organic',
      name: 'Facebook Organic',
      source: 'facebook',
      medium: defaults.mediums.social,
      campaign: 'fb-organic',
      descriptions: {
        content: defaults.contents.autoTagged,
        campaign: defaults.campaigns.organic,
      },
      channelId: 'fb_ig_organic',
    },
    {
      templateId: 'linkedin_organic',
      name: 'LinkedIn Organic',
      source: 'linkedin',
      medium: defaults.mediums.social,
      campaign: 'li-organic',
      descriptions: {
        content: defaults.contents.autoTagged,
        campaign: defaults.campaigns.organic,
      },
      channelId: 'linkedin_organic',
    },
    {
      templateId: 'instagram_organic',
      name: 'Instagram Organic',
      source: 'instagram',
      medium: defaults.mediums.social,
      campaign: 'ig-organic',
      descriptions: {
        content: defaults.contents.autoTagged,
        campaign: defaults.campaigns.organic,
      },
    },
    {
      templateId: 'pinterest_organic',
      name: 'Pinterest Organic',
      source: 'pinterest',
      medium: defaults.mediums.social,
      campaign: 'pin-organic',
      descriptions: {
        content: defaults.contents.autoTagged,
        campaign: defaults.campaigns.organic,
      },
    },
    {
      templateId: 'twitter_organic',
      name: 'Twitter Organic',
      source: 'twitter',
      medium: defaults.mediums.social,
      campaign: 'tw-organic',
      descriptions: {
        content: defaults.contents.autoTagged,
        campaign: defaults.campaigns.organic,
      },
    },
    {
      templateId: 'geofencing',
      name: 'Geofencing',
      source: 'geofencing',
      medium: 'cpm',
      descriptions: {
        campaign: 'Campaign name',
        content: defaults.contents.adtech,
      },
      channelId: 'geofencing_ads',
    },
    {
      templateId: 'em_promotion',
      name: 'EM Promotion',
      medium: defaults.mediums.social,
      descriptions: {
        source: 'Social network, i.e. "facebook", "reddit", etc.',
        campaign: `"em-facebookads", "em-redditads", "em-pinterestads", etc.`,
        content: `Type of content, for example mg, ig, lf, og, etc.`,
      },
    },
    {
      templateId: 'adtechfx_crmretargeting',
      name: 'AdTechFX: CRM Retargeting',
      source: defaults.sources.adtech,
      medium: defaults.mediums.adtech,
      descriptions: {
        campaign: defaults.campaigns.adtech('crm'),
        content: defaults.contents.adtech,
      },
      channelId: 'crm_retargeting',
    },
    {
      templateId: 'adtechfx_abm',
      name: 'AdTechFX: ABM',
      source: defaults.sources.adtech,
      medium: defaults.mediums.adtech,
      descriptions: {
        campaign: defaults.campaigns.adtech('abm'),
        content: defaults.contents.adtech,
      },
      channelId: 'account_marketing',
    },
    {
      templateId: 'adtechfx_audiences',
      name: 'AdTechFX: 3rd-Party Audiences',
      source: defaults.sources.adtech,
      medium: defaults.mediums.adtech,
      descriptions: {
        campaign: defaults.campaigns.adtech('3P'),
        content: defaults.contents.adtech,
      },
      channelId: 'audience_targeting',
    },
    {
      templateId: 'quora_ads',
      name: 'Quora Ads',
      source: 'quora',
      medium: 'paid',
      descriptions: {
        campaign: defaults.campaigns.ads('qu-leadgen'),
        content: defaults.contents.ads,
      },
    },
    {
      templateId: 'traditional_misc',
      name: 'Traditional - MISC',
      medium: 'offline',
      channelId: ['non_trackable', 'vanity_url_phone'],
    },
    {
      templateId: 'traditional_qr',
      name: 'Traditional - QR',
      source: 'qr',
      channelId: 'qr_codes_traditional',
    },
  ];

  // always load static templates first
  const existingData = [...StaticTemplates];

  // append any newly created templates
  if (!isEmpty(newData) && Array.isArray(newData)) {
    newData.forEach((value) => {
      const newStruct = {};

      // add expected template keys
      newStruct.templateId = value.templateId;
      newStruct.name = value.name;
      newStruct.source = value.source;
      newStruct.medium = value.medium;
      newStruct.campaign = value.campaign;
      newStruct.content = value.content;
      newStruct.term = value.term;
      newStruct.url = value.url;
      newStruct.channelId = value.channelId;

      // attach delete event to new data
      newStruct.icon = 'delete';
      newStruct.iconOutlined = true;
      newStruct.iconPosition = 'right';
      newStruct.iconClassName = styles.deleteButton;

      newStruct.iconHasDeleteEvent = true;

      existingData.push(newStruct);
    });
  }

  return existingData;
};

module.exports = campaignUrlGeneratorTemplates;
